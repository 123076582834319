// 自主填报查看数据 部分

import {getCollectedIdList, saveAndGet} from '@/api/collectData';


const state = {
    // 自主收藏的所有学校数据id
    collectedSchoolIds: [],
    isSaveAndGet: true, // 记录下是否是保存 查看按钮来的

}

const getters = {}

const mutations = {
    setIsSaveAndGetM(state, status) {
        state.isSaveAndGet = !!status
    },
    saveAndGetM(state, ids) {
        state.collectedSchoolIds = ids
    },
}
const actions = {
    // 保存数据
    async getSaveAndGetA({commit}, param) {
        let re = await saveAndGet(param)
        let ids = []
        if (re?.code === 1) {
            ids = re?.data?.ids || []
            let studentId = re?.data?.studentId || []
            commit('setStudentStudentId', studentId)
        }
        commit('saveAndGetM', ids) // 保存下所有
        commit('removeSchoolCollectM') // 移除所有
    },

    async getCollectedIdList({commit}, studentId) {
        let re = await getCollectedIdList(studentId)
        let {code, data} = re || {}
        let ids = []
        if (code === 1) {
            ids = data || []
        }
        commit('saveAndGetM', ids) // 保存下所有
    }
}


export default {
    state,
    getters,
    mutations,
    actions
}
