import {request} from '@/network/request.js'
import provinceMap from '@/assets/config/province.json'


//获取组织架构页初始数据
export function sendMsg(data) {
  return request({
    url: '/user/user/sendMsg',
    method: 'post',
    data: data
  })
}

// 分省份请求
const getSearchSchoolUrl = (province) => {
  let pinyin = provinceMap[province]
  return `user/selfAspiration/${pinyin}SchoolPageQuery`
}

//五个搜索
export function reqSearchSchool(province,data) {
  return request({
    url: getSearchSchoolUrl(province),
    method: 'post',
    data: data
  })
}

//用户自主填报表单
export const getStudentRank = (params) => {
  return request({
    url: '/user/selfAspiration/getStudentScoreRank',
    method: 'post',
    data: params
  })
}


//获得滑动条最大最小值
export function getSliderNum(){
  return request({
    url: '/numberConfig/getSliderNum',
    method: 'get',
  })
}


