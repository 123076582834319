/**
 * 总体模块
 * 写以一些总的清除等
 */


const state = {
  curMenu:''
}

const getters = {}

const mutations = {
  clearAllInfoM(state) {
    this.commit('removeUserM') // 登录用户的 userId username token 是否是管理员
    this.commit('removeStudentsM') // 登录用户的所有学员
    this.commit('removeStudentInfoM') // 当前考生 选考等
    this.commit('removeSchoolCollectM') // 自主报考院校

    this.commit('removeStudentRankM') // 考生排名
    this.commit('removeSchoolsListM') // 自主报考搜索列表

    // 自主填报
    this.commit('removeTableQueryM')
    this.commit('removeSchoolsListM')

  },

  // 刷新
  // 从本地加载所有保存的数据
  loadAllData(state) {
    this.commit('loadStudentInfoM') // 自主报考搜索列表
    // this.commit('loadSchoolCollectM') // 自主报考搜索列表
  },

  setCurMenuM(state,menuName){
    state.curMenu = menuName
  }
}
const actions = {
  // async updateUserInfo({commit, dispatch}, params) {
  //     let re = await updateUserInfo(params)
  //     let {code, data} = re || {}
  //     if (code === 1) {
  //         dispatch('getUsersInfoA')
  //         commit('setUserInfoM', data || [])
  //     }
  // },
}


export default {
  state,
  getters,
  mutations,
  actions
}
