// user模块

import {login, getUsersData, deleteUserData, getUsersInfo, updateUserInfo} from '@/api/user';
import {tokenManager, usernameManager, userIdManager, isAdminManager} from '@/utils/storeItem';

const state = {
    token: '',
    username: '', // 用户名
    userId: '', // 用户ID
    isAdmin: '', // 是否是管理员
    students: [], // 用户下的学生
    //账号信息
    userInfo: {
        accountName: '',//用户名
        peopleName: '',//用户姓名
        phone: '',
        identity: '',
        unit: '',
        province: '',
        city: '',
        mailbox: '',
        score: 0,
        fileDownloadNumber: 0,//会员文件下载次数
        intelligentAspirationNumber: 0,//智慧推荐次数
        expiredTime: [],//生效时间
    }
}

const getters = {
    token(state) {
        return state.token || ''
    },
    username: state => state.username,
    userInfo: state => state.userInfo,
}

const mutations = {
    loadUserM(state) {
        // debugger
        state.token = tokenManager.get()
        state.username = usernameManager.get()
        state.userId = userIdManager.get()
        state.isAdmin = isAdminManager.get()
    },
    // 设置学生信息 token username userId
    setUserM(state, data) {
        // debugger
        console.log(data)
        let {name, token, userId, role} = data
        this.commit('storeTokenM', token)
        this.commit('setUsernameM', name)
        this.commit('setUserId', userId)
        this.commit('setIsAdminM', role)
    },
    removeUserM(state) {
        this.commit('removeTokenM')
        this.commit('removeUsernameM')
        this.commit('removeUserId')
        this.commit('removeIsAdminM')
    },
    storeTokenM(state, token) {
        state.token = token
        tokenManager.set(token)
    },
    removeTokenM(state) {
        state.token = ''
        tokenManager.remove()
    },
    // 设置学生名称
    setUserId(state, userId) {
        state.userId = userId
        userIdManager.set(userId)
    },
    removeUserId(state) {
        state.userId = ''
        userIdManager.remove()
    },
    // 设置学生名称
    setUsernameM(state, username) {
        state.username = username
        usernameManager.set(username)
    },
    removeUsernameM(state) {
        state.username = ''
        usernameManager.remove()
    },
    setIsAdminM(state, isAdmin) {
        state.isAdmin = isAdmin
        isAdminManager.set(isAdmin)
    },
    removeIsAdminM(state) {
        state.isAdmin = ''
        isAdminManager.remove()
    },
    // 用户下学生
    setStudentsM(state, students) {
        state.students = students
    },
    removeStudentsM(state, students) {
        state.students = []
    },
    setUserInfoM(state, userInfo) {
        state.userInfo = userInfo
    },

}
const actions = {
    // 登录放组件里
    // async loginA({commit}, params) {
    //   let re = await login(params)
    //   let {code, data} = re || {}
    //   if (code === 1) {
    //     commit('storeTokenM', data)
    //     commit('setUsernameM', params.username || '')
    //   }
    // },

    // 获取用户下所有数据
    async getUsersDataA({commit}) {
        let username = state.username
        let re = await getUsersData(username)
        let {code, data} = re || {}
        if (code === 1) {
            data?.forEach((s, i) => s.index = i)
            commit('setStudentsM', data || [])
            return Promise.resolve({
                first: data?.[0],
                students: data
            })
        }
    },

    // 删除学生
    async deleteUserData({commit, dispatch}, params) {
        let re = await deleteUserData(params)
        let {code, data} = re || {}
        if (code === 1) {
            dispatch('getUsersDataA')
            return Promise.resolve()
        }
    },

    //获取账号信息
    async getUsersInfoA({commit}) {
        let accountName = state.username
        let re = await getUsersInfo(accountName)
        let {code, data} = re || {}
        if (code === 1) {
            commit('setUserInfoM', data || [])
            return Promise.resolve(data)
        }
    },

    //修改账号信息
    async updateUserInfo({commit, dispatch}, params) {
        let re = await updateUserInfo(params)
        let {code, data} = re || {}
        if (code === 1) {
            dispatch('getUsersInfoA')
            commit('setUserInfoM', data || [])
        }
    },

}


export default {
    state,
    getters,
    mutations,
    actions
}
