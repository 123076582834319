//该文件专门用于整个应用的路由器
// import homeVue from '@/home.vue'
import VueRouter from 'vue-router'
import Vue from 'vue'
import {beforeEach, afterEach} from './routerGuard'

Vue.use(VueRouter);
//解决路由重复问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//引入组件
// import UserLogin from './components/login/UserLogin'
// import UserRegister from './components/register/UserRegister.vue'

//创建并暴露一个路由器
const router = new VueRouter({
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/home',
            name: 'home',
            meta: {
                title: '主页'
            },
            component: () => import('../views/home/Home.vue'),
        },
        {
            path: '/register',
            name: 'register',
            component: () => import('@/views/home/Register.vue'),
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/home/Login.vue'),
        },
        {
            path: '/fillPage',
            name: 'fillPage',
            component: () => import('../views/fillPage/FillPage.vue'),
        },
        {
            path: '/myInfo',
            name: 'myInfo',
            component: () => import('../components/MyInfo.vue'),
        },
        {
            path: '/myData',
            name: 'myData',
            component: () => import('../components/MyData.vue'),
        },
        {
            path: '/collectData',
            name: 'collectData',
            component: () => import('../components/CollectData.vue'),
        },
        {
            path: '/toCollectData',
            name: 'toCollectData',
            component: () => import('../components/ToCollectData.vue'),
        },
        {
            path: '/index',
            name: 'index',
            component: () => import('../views/Index.vue'),
        },
        {
            path: '/smartAssistant',
            name: 'smartAssistant',
            component: () => import('../views/smartAssistant/SmartAssistant.vue'),
        },
        {
            path: '/contactUs',
            name: 'contactUs',
            component: () => import('../views/contactUs/ContactUs.vue'),
        },
        {
            path: '/hundredQuestionsAnswers',
            name: 'hundredQuestionsAnswers',
            component: () => import('../views/hundredQuestionsAnswers/HundredQuestionsAnswers.vue'),
        },
        {
            path: '/dataSharing',
            name: 'dataSharing',
            component: () => import('../views/dataSharing/DataSharing.vue'),
        },
        {
            path: '/teacherHelp',
            name: 'teacherHelp',
            component: () => import('../views/teacherHelp/TeacherHelp.vue'),
        },
        {
            path: '/hundredQuestionsAnswersManagement',
            name: 'hundredQuestionsAnswersManagement',
            component: () => import('../views/adminSystem/HundredQuestionsAnswersManagement.vue'),
        },
        {
            path: '/adminHome',
            name: 'adminHome',
            component: () => import('../views/adminSystem/AdminHome.vue'),
        },
        {
            path: '/smartRecommendationTable',
            name: 'SmartRecommendationTable',
            component: () => import('../views/smartAssistant/SmartRecommendationTable.vue'),
        },
        {
            path: '/retrievePassword',
            name: 'retrievePassword',
            component: () => import('../views/home/RetrievePassword.vue'),
        },
    ],
    mode: 'hash',
    base: '/home/',//配置nginx访问结构
    strict: process.env.NODE_ENV !== 'production'
})

router.beforeEach(beforeEach)
router.afterEach(afterEach)
export default router
