/**
 * 单个item封装一些方法
 */
export default class SmartStorage {
    storage;
    key;

    constructor({key}) {
        this.storage = localStorage;
        this.key = key;
    }

    set(value) {
        this.storage.setItem(this.key, this._toString(value));
    }

    get() {
        return this._toVal(this.storage.getItem(this.key));
    }

    remove() {
        this.storage.removeItem(this.key);
    }

    clear() {
        this.storage.clear();
    }

    // 判断是不是字符串
    _isNotString(val) {
        return Object.prototype.toString.call(val) === '[object String]'
    }

    _toString(val) {
        if (this._isNotString(val)) {
            return val
        } else {
            return JSON.stringify(val)
        }
    }

    _toVal(val) {
        let data
        try {
            data = JSON.parse(val)
        } catch (e) {
        }
        return data || val
    }

}


