import axios from 'axios'
import vue from 'vue'
import store from '@/store/store'
import router from '@/router';
import {Notification, MessageBox, Message, Loading} from 'element-ui'
import {saveAs} from 'file-saver'
import {tansParams, blobValidate} from '@/utils/ruoyi';
import errorCode from '@/utils/errorCode'
import {getHeaderFileName} from '@/utils/utils';
import debounce from 'lodash/debounce';

const service = axios.create({
  Timeout: 1000,
  baseURL: '/gaokao'
})
// 请求拦截验证登录状态
service.interceptors.request.use(
    config => {
      // do something before request is sent
      // debugger
      // console.log(store.getters.token)

      deleteUserIdNull(config)

      if (store.getters.token) {
        config.headers['token'] = store.getters.token
      }
      return config
    },
    error => {
      // do something with request error
      console.log(error) // for debug
      return Promise.reject(error)
    }
)

const reg = /&userId=([^&]*)(?:&|$)/
const deleteUserIdNull = (config)=>{
    try {
        if (config.method === 'get') {
            if(Object.hasOwnProperty('userId')){
                if(['null',''].includes(config.params['userId'])){
                    delete config.params['userId']
                }
            }
            const value = config.url.match(reg)?.[1]
            if(value){
                if(['null',''].includes(value)){
                    config.url = config.url.replace(reg,'')
                }
            }
        }
    } catch (e) {
        console.log(e)
    }

}

service.interceptors.response.use(
    response => {
      // 二进制数据则直接返回
      if (response.request.responseType === 'blob' || response.request.responseType === 'arraybuffer') {
        return response
      }
      return response.data;
    },
    error => {
      const {response} = error || {}
      const {status} = response || {}
      switch (status) {
          // 特殊处理
        case 404:
          return Promise.reject(error.response.data)
          break
        case 403:
        case 401:
          tip2()
          return Promise.reject(error.response.data)   // 返回接口返回的错误信息
          break
      }
    });

export function request(config) {
  return service(config).then((data) => {
    const {code} = data || {}
    switch (code) {
      case 1010:
      case 1006:
          tip2()
        return Promise.resolve(data || {})
        break
      default:
        return Promise.resolve(data)
    }
  }).catch((e) => {
    let {error} = e || {}
    if(error){
      vue.prototype.$message.error(`${e.status} ${e.error}`)
    } else {
      vue.prototype.$message.error(`${e.msg}`)
    }
  })
}

let downloadLoadingInstance

export function download(url, params, filename, config) {
  downloadLoadingInstance = Loading.service({
    text: '正在下载数据，请稍候',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  return service.post(url, params, {
    // transformRequest: [(params) => { return tansParams(params) }],
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    ...config
  }).then(async (res) => {
    let {data, headers} = res
    const isBlob = blobValidate(data);
    if (isBlob) {
      const blob = new Blob([data])
      saveAs(blob, filename || getHeaderFileName(headers['content-disposition']))
    } else {
      const resText = await data.text();
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      Message.error(errMsg);
    }
    downloadLoadingInstance.close();
  }).catch((r) => {
    console.error(r)
    Message.error('下载文件出现错误，请联系管理员！')
    downloadLoadingInstance.close();
  })
}

// 资料分析文件下载
export function downloadGet(url, filename, config) {
  downloadLoadingInstance = Loading.service({
    text: '正在下载数据，请稍候',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  return service.get(url, {
    responseType: 'blob',
    ...config
  }).then(async (res) => {
    let {data, headers} = res
    const isBlob = blobValidate(data);
    if (isBlob) {
      const blob = new Blob([data])
      saveAs(blob, filename || getHeaderFileName(headers['content-disposition']))
    } else {
      const resText = await data.text();
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      Message.error(errMsg);
    }
    downloadLoadingInstance.close();
  }).catch((r) => {
    console.error(r)
    Message.error('下载文件出现错误，请联系管理员！')
    downloadLoadingInstance.close();
  })
}


// 提示跳转组件
const tip = () => {
  vue.prototype.$confirm('暂无权限，请登录!', '登录提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    router.push({name: 'login'})
    clearTokenAndInfo()
  }).catch(() => {
    vue.prototype.$message({
      type: 'info',
      message: '已取消'
    });
  });
}
const tip2 = debounce(tip,100)


// 清空token数据
const clearTokenAndInfo = () => {
  store.commit('removeUserM')
}






