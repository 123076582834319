/**
 * @Description: 本地存储数据 管理
 * @author wsl
 * @date 2024/3/31
 */

import SmartStorage from './localStorage'


const token = 'token'
export const tokenManager = new SmartStorage({key: token})

const username = 'username'
export const usernameManager = new SmartStorage({key: username})

const userId = 'userId'
export const userIdManager = new SmartStorage({key: userId})

const collectSchool = 'collectSchool'
// 自主填报收集院校 管理
export const collectSchoolManager = new SmartStorage({key: collectSchool})

const studentInfo = 'studentInfo'
// 当前学生信息保存 包含了 游客
export const studentInfoManager = new SmartStorage({key: studentInfo})

const isAdmin = 'isAdmin'
// 当前学生信息保存 包含了 游客
export const isAdminManager = new SmartStorage({key: isAdmin})




