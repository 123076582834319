/**
 * @Description: 工具类
 * @author wsl
 * @date 2024/3/21
 */


/**
 * 判断是否为空对象
 * @param obj
 * @returns {boolean}
 */
export const isEmptyObj = (obj) => {
  return obj && !Object.keys(obj).length
}

/**
 * 是否为Object
 * @param obj
 * @returns {boolean}
 */
export const isObject = (obj) => {
  return Object.prototype.toString.call(obj) === '[object Object]'
}

/**
 * 复制对象
 * @param obj
 * @returns {{}|Error}
 */
export const copyObj = (obj) => {
  let reObj = {}
  if (isObject(obj)) {
    for (const key in obj) {
      reObj[key] = obj[key]
    }
    return reObj
  } else {
    return new Error('输入对象')
  }
}

export const obj2Form = (obj) => {
  let formData = new FormData()
  for (const key in obj) {
    let value = obj[key]
    formData.append(key, value)
  }

  return formData
}

/**
 * 设置 collected
 * @param arr
 * @param hasArr
 */
export const signArr = (arr, hasArr) => {
  const map = {}
  hasArr.forEach((item, index) => {
    let {id} = item
    map[id] = item
  })

  arr.forEach((item, index) => {
    let {id} = item
    if (map[id]) {
      item.collected = true
    }
  })
}

/**
 * 交换两个数据
 * @param arr
 * @param index1
 * @param index2
 * @returns {boolean} 是否过界
 */
export const swap = (arr, index1, index2) => {
  if(arr[index1] &&　arr[index2]){
    const temp = arr[index1];
    arr.splice(index1, 1, arr[index2]);
    arr.splice(index2, 1, temp);
    return false
  } else {
    return true
  }
}

export const getHeaderFileName = (str) => {

  let fileName = '导出数据.xlsx'
  try {
    let strArr = str?.split('\'\'')
    fileName = decodeURIComponent(strArr.pop())
  } catch (e) {

  }
  return fileName
}




