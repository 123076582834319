import Vue from 'vue'
import axios from 'axios'
import Vuex from 'vuex';
import ElementUI from 'element-ui'

Vue.use(ElementUI);


import App from './App.vue'
import router from './router'
import 'element-ui/lib/theme-chalk/index.css'
import store from './store/store';

Vue.prototype.axios = axios;

Vue.config.productionTip = false
// const router = new VueRouter()
//解决路由重复问题
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
// 	return originalPush.call(this, location).catch(err => err)
// }
Vue.use(Vuex);
new Vue({
    el: '#app',
    // 将 store 实例作为插件添加
    render: h => h(App),
    router: router,
    store:store
}).$mount('#app')
