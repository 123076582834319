<template>
  <div id="app">
    <div class="index">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState, mapActions, mapMutations} from 'vuex'

export default {
  name: 'app',
  components: {},
  computed: {
    token: state => {
      return state.user.token
    },
  },
  created() {
    this.loadUserM()
    this.loadSchoolCollectM() // 未登录用户记录
    this.loadAllData() // 未登录用户记录
  },
  methods: {
    ...mapMutations(['loadUserM', 'loadSchoolCollectM', 'loadAllData']),
  }
}
</script>

<style lang="less">
html {
  overflow-y: auto;
  height: 100%;

  body {
    height: 100%;

    #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
      height: 100%;

      .index {
        height: 100%;
        width: 100%;
        background-color: rgb(229, 230, 232);
        //background-image: linear-gradient(rgb(223, 239, 245), #ccdeec);
      }
    }
  }
}

@media (max-width: 768px) {
  html {
    overflow-y: auto;
    height: auto;

    body {
      height: auto;

      #app {


        .index {

        }
      }
    }
  }
}
</style>
