/**
 * @Description: 学生信息 的处理方法
 * @author wsl
 * @date 2024/3/20
 */

import {isEmptyObj, isObject} from '../utils'
// 基础格式
const studentInfo = {
  biology: 0,
  chemistry: 0,
  geography: 0,
  history: 0,
  physics: 0,
  politics: 0,

  name: '',
  phone: '',
  province: '',
  score: '',
  rank: '',
  sex: '',
  studentId: '',
}

/**
 * 返回空空数组
 * @returns {{}}
 */
export const getStudentInfoEmpty = () => {
  const obj = {}
  for (const key in studentInfo) {
    obj[key] = studentInfo[key]
  }
  return obj
}


/**
 *
 * @param obj
 * @returns {*|Error}
 */
export const copyObj = (obj) => {
  let reObj = {}
  if (isObject(obj)) {
    for (const key in studentInfo) {
      reObj[key] = obj[key]
    }
    return reObj
  } else {
    return new Error('输入对象')
  }
}


/**
 * 验证 + 拷贝
 * @param Obj
 * @returns {studentInfo}
 */
export const checkStudentInfo = (Obj) => {
  let studentInfo
  if (isEmptyObj(Obj)) {
    studentInfo = getStudentInfoEmpty()
  } else {
    studentInfo = copyObj(Obj)
  }
  return studentInfo
}







