import {
    request
} from '@/network/request.js'



//智慧推荐
//用户智慧填报
export function getSmartAssistant(data) {
    return request({
        url: '/user/intelligentAspiration/get',
        method: 'post',
        data: data
    })
}


// 本科获取专业就业方向
export function getUndergraduateProfessionDetail(profession) {
    return request({
        url: `/undergraduateProfessionDetail/getProfessionDetail/${profession}`,
        method: 'get',
    })
}

// 本科专业就业表导入
export function undergraduateProfessionDetailImport(data) {
    return request({
        url: `/undergraduateProfessionDetail/undergraduateProfessionDetailImport`,
        method: 'post',
        data:data
    })
}


// 专科获取专业就业方向
export function getJuniorProfessionDetail(profession) {
    return request({
        url: `/juniorProfessionDetail/getProfessionDetail/${profession}`,
        method: 'get',
    })
}

// 专科专业就业表导入
export function juniorProfessionDetailImport(data) {
    return request({
        url: `/juniorProfessionDetail/juniorProfessionDetailImport`,
        method: 'post',
        data:data
    })
}


// 提交福利码
export function submitWelFareCode(welfareCode,userId) {
    return request({
        url: `/welfareCode/validate?welfareCode=${welfareCode}&userId=${userId}`,
        method: 'put',

    })
}





