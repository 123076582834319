import {request} from '@/network/request.js'


// 获取验证码
export function getVerificationCode() {
    return request({
        url: '/captcha',
        method: 'get',
    })
}

export function login(data) {
    return request({
        url: '/login',
        method: 'post',
        data
    })
}


export function register(data) {
    return request({
        url: '/register',
        method: 'post',
        data
    })
}

// 登出
export function logout() {
    return request({
        url: '/logout',
        method: 'get'
    })
}

// 获取用户下学生数据
export function getUsersData(username) {
    return request({
        url: `/data/${username}`,
        method: 'get',
    })
}

// 获取用户下学生数据
export function deleteUserData(data) {
    return request({
        url: `/delete`,
        method: 'delete',
        data
    })
}

//查看用户账号信息
export function getUsersInfo(username) {
    console.log(username)
    return request({
        url: `/user/get`,
        method: 'get',
        params: {
            accountName: username
        }
    })
}

//修改账号信息
export function updateUserInfo(data) {
    return request({
        url: `/user/updateUserInfo`,
        method: 'post',
        data
    })
}

//修改账号密码
export function updateUserPwd(data) {
    return request({
        url: `/user/updateUserPwd`,
        method: 'post',
        data
    })
}

//邮箱发送验证码
export function sendMail({username, mail}) {
    return request({
        url: `/user/sendMail?username=${username}&mail=${mail}`,
        method: 'get',
        // params:{
        //   username:username,
        //   mail:mailbox
        // }
    })
}

//重置密码
export function resetPwd(data) {
    return request({
        url: `/user/resetPwd`,
        method: 'post',
        data
    })
}

//获取用户福利信息
export function getMyWelfareInfo(userId) {
    return request({
        url: `/getMyWelfareInfo/${userId}`,
        method: 'get'
    })
}