import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


import fillPage from './fillPage';
import adminSystem from './adminSystem';
import user from './user';
import smartAssistant from'./smartAssistant'
import collectedSchool from './collectedSchool'
import global from './global'

export default new Vuex.Store({
  modules: {
    fillPage,
    adminSystem,
    user,
    smartAssistant,
    collectedSchool,
    global,
  }
})
