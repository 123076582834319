import {request} from '@/network/request.js'


//智慧填报冲、稳、保、垫的志愿数量设置
// export function uploadFiles(category,membership_price,name,price,data) {
//     return request({
//         url: `/file/upload?category=${category}&membership_price=${membership_price}&name=${name}$price=${price}`,
//         method: 'post',
//         data:data
//
//     })
// }
export function uploadFiles(param, data) {
    return request({
        url: `/file/upload`,
        method: 'post',
        data: data,
        params: param,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export function updateFiles(param, data) {
    return request({
        url: `/file/update`,
        method: 'post',
        data: data,
        params: param,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

//管理员查看已上传数据
export function getAllFiles() {
    return request({
        url: `/file/getAll`,
        method: 'get'
    })
}

//文件删除

export function deleteFiles(fileName) {
    return request({
        url: `/file/delete/${fileName}`,
        method: 'delete'
    })
}
