import {request} from '@/network/request.js'


//智慧填报冲、稳、保、垫的志愿数量设置
export function intelligentFillSet(data) {
    return request({
        url: `/admin/intelligentFillSet/intelligentFillSet`,
        method: 'post',
        data: data
    })
}

//查看智慧填报冲、稳、保、垫的志愿数量

export function getIntelligentFillSet({province, juniorOrUndergraduate}) {
    return request({
        url: `/admin/intelligentFillSet/getIntelligentFillSet?province=${province}&juniorOrUndergraduate=${juniorOrUndergraduate}`,
        method: 'post',
        data: {
            province
        }
    })
}
