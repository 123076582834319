//智慧推荐模块
import {getSmartAssistant} from '@/api/smartAssistant';
import {getStudentRank} from '@/api/fillPage';

/**
 *
 *  智慧推荐模块 自主填报用户信息是分开的
 *
 *  */
const state =
  {
    curStudentInfo: { // 当前考生信息
      'biology': 0,
      'chemistry': 0,
      'geography': 0,
      'history': 0,
      'name': '',
      'phone': '',
      'physics': 0,
      'politics': 0,
      'province': '辽宁',
      'score': null,
      'sex': '',
    },
    curStudentScoreRank: {},

    // 展示列表的页码和页数
    tableQuery: {
      page: 1,
      pageSize: 10,
    },
    tablePageInfo: {
      pages: 0,
      total: 0
    }
  }
const getters = {
  smartRecommendationList: state => state.smartRecommendationList,
}
const mutations = {
  commitStudentInfoM(state, studentInfo) {
    state.curStudentInfo = studentInfo
  },
  setTablePageInfo(state, data) {
    let {pages, total} = data || {}
    state.tablePageInfo = {
      pages: pages || 0,
      total: total || 0
    }
  },
  setSmartRecommendationListM(state, schools) {
    state.schoolsList = schools
  },
  commitStudentRankM(state, rank) {
    state.curStudentScoreRank = rank
  },
}
const actions = {
  async getSmartRecommendationListA({commit}, params) {
    let {province, schoolSearchParam} = params
    let re = await getSmartAssistant(province, schoolSearchParam)
    if (re?.code === 1) {
      commit('setSmartRecommendationListM', re?.data?.list || [])
      commit('setTablePageInfo', re?.data || {})
    }
  },

  // 获取分数线
  async getGradeLineA({commit}, params) {
    let re = await getStudentRank(params,)
    let data = []

    if (re?.code === 1) {
      data = re?.data || []
      params.thisYearScoreRank = data.thisYearScoreRank
    }
    commit('commitStudentInfoM', params)
    commit('commitStudentRankM', data)
  },


}


export default {
  state,
  getters,
  mutations,
  actions
}

