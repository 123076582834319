/**
 * @Description: 路由守卫
 * @author wsl
 * @date 2024/4/21
 */

// import collectedSchool from '@/store/collectedSchool'
import store from '@/store/store'
import vue from 'vue';

export const beforeEach = (to, from, next) => {
    let toName = to.name
    let fromName = from.name

    store.commit('setCurMenuM', toName)

    switch (toName) {
        case 'home':
            break
        case 'collectData':
            if (fromName === 'fillPage') {
                store.commit('setIsSaveAndGetM', true)
            } else {
                store.commit('setIsSaveAndGetM')
            }
            break
        case 'login':
        case 'register':
            store.commit('clearAllInfoM')
            break
    }


    next()
}


export const afterEach = (to, from, next) => {
    // let toName = to.name
    // let fromName = from.name
    //
    // switch (toName) {
    //     case 'home':
    //         break
    // }
}





