import {intelligentFillSet} from '@/api/admin/smartAssistantManagement';
import {updateFiles, uploadFiles} from '@/api/admin/dataSharingManagement';
import {obj2Form} from '@/utils/utils';


const state = {
    currentComponent: 'FillPageManagement',
    province: '辽宁',
    juniorOrUndergraduate: '本科',
    //文件上传
    items: [{
        image: null,
        name: '',
        price: '',
        membership_price: '',
        category: '',
        file: null
    }],//文件上传新条目

    smartAssistantSetInfo: {
        province: '',
        aspirationTotalNumber: null,
        //冲志愿数量
        rushAspirationNumber: null,
        firstRushAspirationNumber: null,
        secondRushAspirationNumber: null,
        thirdRushAspirationNumber: null,
        fourthRushAspirationNumber: null,
        fifthRushAspirationNumber: null,
        sixthRushAspirationNumber: null,
        seventhRushAspirationNumber: null,
        eighthRushAspirationNumber: null,

        //稳志愿数量
        steadyAspirationNumber: null,
        firstSteadyAspirationNumber: null,
        secondSteadyAspirationNumber: null,
        thirdSteadyAspirationNumber: null,
        fourthSteadyAspirationNumber: null,
        fifthSteadyAspirationNumber: null,
        sixthSteadyAspirationNumber: null,
        seventhSteadyAspirationNumber: null,
        eighthSteadyAspirationNumber: null,

        //保志愿数量
        ensureAspirationNumber: null,
        firstEnsureAspirationNumber: null,
        secondEnsureAspirationNumber: null,
        thirdEnsureAspirationNumber: null,
        fourthEnsureAspirationNumber: null,
        fifthEnsureAspirationNumber: null,
        sixthEnsureAspirationNumber: null,
        seventhEnsureAspirationNumber: null,
        eighthEnsureAspirationNumber: null,

        //垫志愿数量
        padAspirationNumber: null,
        firstPadAspirationNumber: null,
        secondPadAspirationNumber: null,
        thirdPadAspirationNumber: null,
        fourthPadAspirationNumber: null,
        fifthPadAspirationNumber: null,
        sixthPadAspirationNumber: null,
        seventhPadAspirationNumber: null,
        eighthPadAspirationNumber: null,

        //冲志愿分数差
        minRushScoreDifference: null,
        maxRushScoreDifference: null,

        //稳志愿分数差
        minSteadyScoreDifference: null,
        maxSteadyScoreDifference: null,

        //保志愿分数差
        minEnsureScoreDifference: null,
        maxEnsureScoreDifference: null,

        //垫志愿分数差
        minPadScoreDifference: null,
        maxPadScoreDifference: null,

        //某志愿最少录取人数
        number: null,
    },
    heBeiSmartAssistantSetInfo: {
        province: '',
        aspirationTotalNumber: null,
        //冲志愿数量
        rushAspirationNumber: null,
        firstRushAspirationNumber: null,
        secondRushAspirationNumber: null,
        thirdRushAspirationNumber: null,
        fourthRushAspirationNumber: null,
        fifthRushAspirationNumber: null,
        sixthRushAspirationNumber: null,
        seventhRushAspirationNumber: null,
        eighthRushAspirationNumber: null,

        //稳志愿数量
        steadyAspirationNumber: null,
        firstSteadyAspirationNumber: null,
        secondSteadyAspirationNumber: null,
        thirdSteadyAspirationNumber: null,
        fourthSteadyAspirationNumber: null,
        fifthSteadyAspirationNumber: null,
        sixthSteadyAspirationNumber: null,
        seventhSteadyAspirationNumber: null,
        eighthSteadyAspirationNumber: null,

        //保志愿数量
        ensureAspirationNumber: null,
        firstEnsureAspirationNumber: null,
        secondEnsureAspirationNumber: null,
        thirdEnsureAspirationNumber: null,
        fourthEnsureAspirationNumber: null,
        fifthEnsureAspirationNumber: null,
        sixthEnsureAspirationNumber: null,
        seventhEnsureAspirationNumber: null,
        eighthEnsureAspirationNumber: null,

        //垫志愿数量
        padAspirationNumber: null,
        firstPadAspirationNumber: null,
        secondPadAspirationNumber: null,
        thirdPadAspirationNumber: null,
        fourthPadAspirationNumber: null,
        fifthPadAspirationNumber: null,
        sixthPadAspirationNumber: null,
        seventhPadAspirationNumber: null,
        eighthPadAspirationNumber: null,

        //冲志愿分数差
        minRushScoreDifference: null,
        maxRushScoreDifference: null,

        //稳志愿分数差
        minSteadyScoreDifference: null,
        maxSteadyScoreDifference: null,

        //保志愿分数差
        minEnsureScoreDifference: null,
        maxEnsureScoreDifference: null,

        //垫志愿分数差
        minPadScoreDifference: null,
        maxPadScoreDifference: null,

        //某志愿最少录取人数
        number: null,
    },
    jiLinSmartAssistantSetInfo: JSON.parse(localStorage.getItem('jiLinSmartAssistantSetInfo')) || {
        province: '',
        aspirationTotalNumber: null,
        //冲志愿数量
        rushAspirationNumber: null,
        firstRushAspirationNumber: null,
        secondRushAspirationNumber: null,
        thirdRushAspirationNumber: null,
        fourthRushAspirationNumber: null,
        fifthRushAspirationNumber: null,
        sixthRushAspirationNumber: null,
        seventhRushAspirationNumber: null,
        eighthRushAspirationNumber: null,

        //稳志愿数量
        steadyAspirationNumber: null,
        firstSteadyAspirationNumber: null,
        secondSteadyAspirationNumber: null,
        thirdSteadyAspirationNumber: null,
        fourthSteadyAspirationNumber: null,
        fifthSteadyAspirationNumber: null,
        sixthSteadyAspirationNumber: null,
        seventhSteadyAspirationNumber: null,
        eighthSteadyAspirationNumber: null,

        //保志愿数量
        ensureAspirationNumber: null,
        firstEnsureAspirationNumber: null,
        secondEnsureAspirationNumber: null,
        thirdEnsureAspirationNumber: null,
        fourthEnsureAspirationNumber: null,
        fifthEnsureAspirationNumber: null,
        sixthEnsureAspirationNumber: null,
        seventhEnsureAspirationNumber: null,
        eighthEnsureAspirationNumber: null,

        //垫志愿数量
        padAspirationNumber: null,
        firstPadAspirationNumber: null,
        secondPadAspirationNumber: null,
        thirdPadAspirationNumber: null,
        fourthPadAspirationNumber: null,
        fifthPadAspirationNumber: null,
        sixthPadAspirationNumber: null,
        seventhPadAspirationNumber: null,
        eighthPadAspirationNumber: null,

        //冲志愿分数差
        minRushScoreDifference: null,
        maxRushScoreDifference: null,

        //稳志愿分数差
        minSteadyScoreDifference: null,
        maxSteadyScoreDifference: null,

        //保志愿分数差
        minEnsureScoreDifference: null,
        maxEnsureScoreDifference: null,

        //垫志愿分数差
        minPadScoreDifference: null,
        maxPadScoreDifference: null,

        //某志愿最少录取人数
        number: null
    }
}
const getters = {}
const mutations = {
    setCurrentComponent(state, componentName) {
        state.currentComponent = componentName;
        localStorage.setItem('lastEditedComponent', componentName);
    },
    setProvinceM(state, province) {
        state.province = province
    },
    setJuniorOrUndergraduateM(state, juniorOrUndergraduate) {
        state.juniorOrUndergraduate = juniorOrUndergraduate
    },
    // setSmartAssistantSetInfoM(state, smartAssistantSetInfo) {
    //     state.smartAssistantSetInfo = smartAssistantSetInfo;
    //     // localStorage.setItem('smartAssistantSetInfo', JSON.stringify(smartAssistantSetInfo));
    // },
    // setHeBeiSmartAssistantSetInfoM(state, heBeiSmartAssistantSetInfo) {
    //     state.heBeiSmartAssistantSetInfo = heBeiSmartAssistantSetInfo;
    //     // localStorage.setItem('heBeiSmartAssistantSetInfo', JSON.stringify(heBeiSmartAssistantSetInfo));
    // },
    setJiLinSmartAssistantSetInfoM(state, jiLinSmartAssistantSetInfo) {
        state.jiLinSmartAssistantSetInfo = jiLinSmartAssistantSetInfo;
        localStorage.setItem('jiLinSmartAssistantSetInfo', JSON.stringify(jiLinSmartAssistantSetInfo));

    },

    ADD_ITEM(state, item) {
        state.items.push(item);
        localStorage.setItem('items', JSON.stringify(state.items)); // 确保 localStorage 更新
    },
    REMOVE_ITEM(state, index) {
        state.items.splice(index, 1);
        localStorage.setItem('items', JSON.stringify(state.items)); // 更新 localStorage
    },
    SET_ITEMS(state, items) {
        state.items = items;
    },
    UPDATA_ITEMS(state, items) {
        state.items = items
    }
}

const actions = {
    selectComponent({commit}, componentName) {
        commit('setCurrentComponent', componentName);
    },
    //设置智慧助手管理
    // async getSmartAssistantSetInfoA({commit}, {params, province}) {
    //     // commit('setSmartAssistantSetInfoM', params)
    //     // commit('setHeBeiSmartAssistantSetInfoM', params)
    //     let re = await intelligentFillSet(params, province)
    //     let {code, data} = re || {}
    //     if (code === 1) {
    //
    //     }
    // },


    initializeItems({commit}) {
        const storedItems = localStorage.getItem('items');
        if (storedItems) {
            commit('SET_ITEMS', JSON.parse(storedItems));
        }
    },
    //文件上传
    async addItemA({commit}, item) {
        // console.log(item)
        // commit('ADD_ITEM', item);
        let field = ['fileName', 'price', 'membershipPrice', 'category',]
        let formField = ['file', 'image']

        let param = {}
        field.forEach((f) => {
            if (f === 'fileName') {
                param['name'] = item['fileName']
            } else {
                param[f] = item[f]
            }
        })

        let query = {}
        formField.forEach((f) => {
            query[f] = item[f]
        })

        let form = obj2Form(query)

        let re = await uploadFiles(param, form)

        return Promise.resolve(re)
    },
    async editItemA({commit}, item) {
        let field = ['fileName','id', 'price', 'membershipPrice', 'category',]
        let formField = ['file', 'image']

        let param = {}
        field.forEach((f) => {
            if (f === 'fileName') {
                param['name'] = item['fileName']
            } else {
                param[f] = item[f]
            }
        })

        let query = {}
        formField.forEach((f) => {
            item[f] && (query[f] = item[f])
        })

        let form = obj2Form(query)

        let re = await updateFiles(param, form)

        return Promise.resolve(re)
    },
    //文件删除
    removeItem({commit}, index) {
        commit('REMOVE_ITEM', index);
    },
}
export default {
    state,
    getters,
    mutations,
    actions
}


