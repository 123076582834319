import {download, request} from '@/network/request.js'

//获取个人收藏数据
export function getCollectData(data) {
    return request({
        url: '/user/selfAspiration/lookCollectionData',
        method: 'post',
        data: data
    })
}

//保存个人自主填报数据
export function saveCollectData(data) {
    return request({
        url: '/user/personalData/selfSave',
        method: 'post',
        data: data
    })
}

//清空所有志愿
export function deleteAllCollectData(data) {
    return request({
        url: '/user/personalData/deleteAll',
        method: 'delete',
        data: data
    })
}


// 导出
export function exportCollectData(data) {
    return request({
        url: '/user/intelligentAspiration/look',
        method: 'post',
        data: data
    })
}

//导出智慧推荐数据
export function exportIntelligent(data, fileName) {
    let url = '/user/personalData/exportIntelligent'
    return download(url, data, fileName)
}

//会员导出自主填报数据
export function exportUserPersonalData(data, fileName) {
    let url = '/user/personalData/export'
    return download(url, data, fileName)
}

//游客导出
export function visitorExport(data,fileName) {
    let url='/user/personalData/visitorExport'
    return download(url,data,fileName)

}

// 保存查看
export function saveAndGet(params) {
    return request({
        url: '/user/selfAspiration/saveAndGet',
        method: 'post',
        data: params
    })
}

// 获取所有收藏的数据Ids
export function getCollectedIdList(studentId) {
    return request({
        url: `/user/personalData/getCollectedIdList/${studentId}`,
        method: 'get',
    })
}

//智慧推荐数据添加到自主填报
export function IntelligentSave(params) {
    return request({
        url: '/user/personalData/IntelligentSave',
        method: 'post',
        data: params
    })
}







