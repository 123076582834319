// fillPage模块
import {reqSearchSchool, getStudentRank, getSliderNum, saveAndGet} from '@/api/fillPage'
import Vue from 'vue'
import {collectSchoolManager, studentInfoManager} from '@/utils/storeItem';
import {copyObj, swap} from '@/utils/utils';
import {getStudentInfoEmpty} from '@/utils/item/studentInfo';


const state = {
    schoolInfo: null, // 院校专业介绍row
    // 存储用户收藏的行
    selectedRows: [],
    // 查询的学校列表
    schoolsList: [],
    // 当前考生信息
    curStudentInfo: {
        physics: 0,
        history: 0,

        politics: 0,
        biology: 0,
        chemistry: 0,
        geography: 0,

        name: '',
        phone: '',
        province: '',
        score: '',
        rank: '',
    },
    // 当前学生排名等数据
    curStudentScoreRank: {},
    // 请求参数,保存下，修改page pageSize
    tableQuery: {
        'floatRank': 0,
        'floatScore': 0,
        'institutionName': '',
        'lastYearScore': 284,
        'lastYearScoreRank': 0,
        'professionalName': '',
        'subject': '历史',
        'thisYearScore': '300',
        'cityLevel': '',
        'province': '',

        biology: 0,
        chemistry: 0,
        geography: 0,
        history: 0,
        physics: 0,
        politics: 0,

        page: 1,
        pageSize: 10
    },
    tablePageInfo: {
        pages: 1,
        total: 0
    },
    //滑动条最大最小值
    numberData: {},
}

const getters = {
    selectedRows: state => state.selectedRows
}

const mutations = {
    TOGGLE_FAVORITE(state, row) {
        const index = state.selectedRows.findIndex((r) => r.index === row.index);
        if (index !== -1) {
            state.selectedRows.splice(index, 1);
        } else {
            state.selectedRows.push(row);
        }
    },
    setSchoolInfoM(state, school) {
        state.schoolInfo = school;
    },
    setSchoolsListM(state, schools) {
        state.schoolsList = schools
    },
    removeSchoolsListM(state) {
        state.schoolsList = []
    },
    // 请求有多少页，总数
    setTablePageInfo(state, data) {
        let {pages, total} = data || {}
        state.tablePageInfo = {
            pages: pages || 1,
            total: total || 0
        }
    },
    // 游客数据保存
    sortSchoolCollectM(state, {type, row}) {
        let index = state.selectedRows.findIndex(s => s.id === row.id)
        if (index === -1) return
        let index2
        switch (type) {
            case 'up':
                index2 = index - 1
                if (index2 >= 0) {
                    swap(state.selectedRows, index, index2)
                }
                break
            case 'down':
                index2 = index + 1
                if (index2 <= state.selectedRows.length - 1) {
                    swap(state.selectedRows, index, index2)
                }
                break
            case 'delete':
                state.selectedRows.splice(index, 1)
                break
        }
        collectSchoolManager.set(state.selectedRows)
    },
    removeSchoolCollectM(state) {
        state.selectedRows = []
        collectSchoolManager.remove()
    },
    loadSchoolCollectM(state) {
        let collectSchools = collectSchoolManager.get()
        if (collectSchools) {
            state.selectedRows = collectSchools
        }
    },
    addSchoolCollectM(state, {id, collected}) {
        let stateSchool = state.schoolsList.find(s => s.id === id)
        stateSchool && Vue.set(stateSchool, 'collected', collected)
        if (!stateSchool) return
        if (collected) {
            state.selectedRows.push(stateSchool)
        } else {
            let index = state.selectedRows.findIndex(s => s.id === id)
            state.selectedRows.splice(index, 1)
        }
        collectSchoolManager.set(state.selectedRows)
    },
    // 从智慧推荐页面加
    addSchoolCollectFromSmartM(state, {school, collected}) {
        let index = state.selectedRows.findIndex(s => s.id === school.id)
        if (collected) {
            if (index !== -1) {
                state.selectedRows.splice(index, 1)
            }
            state.selectedRows.push(school)
        } else {
            if (index !== -1) {
                state.selectedRows.splice(index, 1)
            }
        }
    },

    commitStudentRankM(state, rank) {
        state.curStudentScoreRank = rank
    },
    removeStudentRankM(state) {
        state.curStudentScoreRank = {}
    },

    removeStudentInfoM(state) {
        state.curStudentInfo = getStudentInfoEmpty()
        studentInfoManager.remove()
    },
    loadStudentInfoM(state) {
        let studentInfo = studentInfoManager.get()
        if (studentInfo) {
            state.curStudentInfo = studentInfo
        }
    },
    commitStudentInfoM(state, studentInfo) {
        this.commit('removeStudentRankM')
        studentInfoManager.set(studentInfo)
        if (studentInfo.studentId !== state.curStudentInfo.studentId) {
            this.commit('removeTableQueryM')
        }
        state.curStudentInfo = studentInfo
    },
    setStudentStudentId(state, studentId) {
        state.curStudentInfo.studentId = studentId
        this.commit('commitStudentInfoM', state.curStudentInfo)
    },

    // 设置页码和每页个数
    setTableQueryM(state, tableQuery) {
        state.tableQuery = tableQuery || {
            page: 1,
            pageSize: 10
        }
        this.commit('removeSchoolsListM')
    },
    removeTableQueryM(state) {
        state.tableQuery = {
            page: 1,
            pageSize: 10
        }
        this.commit('removeSchoolsListM')
    },

    setSliderNumM(state, data) {
        state.numberData = data || {}
    }
}
const actions = {
    toggleFavorite({commit}, row) {
        commit('TOGGLE_FAVORITE', row);
    },
    async getSchoolsListA({commit}, params) {

        let {province, tableQuery} = params

        // 处理专业问题
        let query = copyObj(tableQuery)
        query.professionalName = query.professionalName?.replace(/[\(本\)|'(专)']/g, '') || ''

        let re = await reqSearchSchool(province, query)
        commit('setTableQueryM', tableQuery)

        if (re?.code === 1) {
            commit('setSchoolsListM', re?.data?.list || [])
            commit('setTablePageInfo', re?.data || {})
        }
    },

    // 获取学生排名
    async getStudentRankA({commit}, params) {
        commit('commitStudentInfoM', params)

        let re = await getStudentRank(params)
        let data = []
        if (re?.code === 1) {
            data = re?.data || []
            params.thisYearScoreRank = data.thisYearScoreRank
            commit('commitStudentInfoM', params) // 再次提交
        }
        commit('commitStudentRankM', data)
    },

    //获得滑动条最大最小值
    async getSliderNumA({commit}) {
        let re = await getSliderNum()
        let {code, data} = re || {}
        if (code === 1) {
            commit('setSliderNumM', data)
        }

    },

}
export default {
    state,
    getters,
    mutations,
    actions
}


